* {
  padding: 0;
  margin: 0;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

h1{
  color: white !important;
  font-weight: 700 !important;
  /* letter-spacing: 1.4px; */
}

.sticky{
  position: sticky;
  top: 0;
  z-index: 21;
}
.awssld{
  height: 100vh !important;
}
.awssld__content{
  height: 100vh !important;
}

.awssld__content>div{
  width: 100vw;
}