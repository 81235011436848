.contact-page{
    background-color: #004EB6;
    padding-bottom: 100px;
    z-index: 2;
  }
  
  .header {
    text-align: center;
    font-size: 50px;
    font-weight: 700;
    color: #004EB6;
  }
  .line {
    width: 400px;
    margin: 5px auto;
    height: 4px;
    background-color: #004EB6;
    border: none;
  }
  .CONTACT {
    margin: 40px 0 60px 0;
    font-size: 2.25rem;
    color: white;
  }
  
  .contact-form{
    width: 50%;
    margin: auto;
    display: flex;
    flex-direction: column;
    z-index: 2;

  }
  
  .contact-input{
    background: #023e8a;
    border: 0;
    box-sizing: border-box;
    color: #fff;
    display: block;
    font-size: 12pt;
    margin-bottom: 3px;
    outline: none;
    padding: 10px 15px;
    width: 100%;
    -webkit-appearance: none;
    border-radius: 0 !important;
    z-index: 2;

  }
  
  .contact-submit{
    padding: 15px 15px;
    width: 120px;
    align-self: center;
    margin-top: 30px;
    background-color: #F36258;
    border: none;
    color: white;
    font-size: 14px;
    font-weight: 500;
    -webkit-appearance: none;
    border-radius: 0 !important;
    z-index: 2;
    cursor: pointer;
  }
  
  .contact-submit:hover{
    opacity: .7;
  }
  
  .contact-submit:active{
    opacity: .7;
  }
  
  .success-message{
    font-size: 12px;
    color: white;
    align-self: flex-end;
  }
  
  .contact-svg{
    height: 75px !important;
  }
  
  /* Media query for Mobile */
  
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    /* Styles */
    .line {
      width: 250px;
    }
    .header {
      margin-top: 10px;
      padding-top: 10px;
    }
    .CONTACT {
      font-size: 1.5rem;
      margin-bottom: 25px;
    }
    .contact-form{
      width: 90%;
    }
  }
  
  /* Media query for Tablet */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    /* Styles */
    .contact-form{
      width: 70%;
    }
  }